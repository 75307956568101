import { ChangeDetectorRef, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ContactUsService } from '../../services/contactUs.service';
import { NgxCaptchaService } from '@binssoft/ngx-captcha';
import { UserIdService } from '@spartacus/core';
import { Router } from '@angular/router';
import { usersService } from '../../../Users/services/users.service';
import { AppConstants } from '../../../../SGRE-shared/constants/app-constant';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';
import { StorageService } from '../../../../SGRE-shared/services/storage.service';
import { DropdownChangeEvent } from 'primeng/dropdown';

@Component({
  selector: 'app-contactusform',
  templateUrl: './contactusform.component.html',
  styleUrl: './contactusform.component.scss',
})
export class ContactusformComponent {
  @HostListener('click') onClick() {
    if (this.isSuccess === 1) {
      this.isSuccess = 0;
      this.router.navigate([AppConstants.routeUrls.home]);
      this.display = 'none';
    } else {
      this.display = 'none';
    }
  }

  @ViewChild('fileUploader') fileUploader: ElementRef;

  selectedDay: string = '';
  uploadFiles: any = [];
  data: {} = {};
  file: any;
  typeFlag: boolean = false;
  submitted: boolean = false;
  captchIndex: number = 0;
  myForm: FormGroup = new FormGroup({
    subject: new FormControl(''),
    email: new FormControl(''),
    message: new FormControl(''),
    document: new FormControl(''),
  });
  siteKey: string | undefined = '6Lf_ZcYpAAAAAIg4vn-4teuhW05z-uaLGnlqk4bY';
  result: any;
  statuscode: boolean;
  validation: boolean = false;
  modalpopup: boolean;
  captchaStatus: any = null;
  statusvalue: boolean = false;
  statusvalueinvalid: boolean;
  fileType: string = '';
  captchaConfig: any = {
    type: 4, // 1 or 2 or 3 or 4
    length: 6,
    cssClass: 'custom',
    back: {
      stroke: '#2F9688',
      solid: '#f2efd2',
    },
    font: {
      color: '#000000',
      size: '35px',
    },
  };
  statusvaluevalid: boolean;
  userId: any;
  email: any;
  textValue: any;
  display: any;
  isSuccess: any = 0;
  errorFlag: boolean = false;
  subjectOption: any[] = [
    { label: "contactUs.accountIssues", value: "Account Issues" },
    { label: "contactUs.orderStatus", value: "Order Status" },
    { label: "contactUs.shopHelp", value: "Shop Help" },
    { label: "contactUs.technicalIssues", value: "Technical Issues" },
    { label: "contactUs.productList", value: "Product List" },
    { label: "contactUs.other", value: "Other" },
  ];
  selectedSubject: string;
  sizeFlag: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private service: ContactUsService,
    private captchaService: NgxCaptchaService,
    private router: Router,
    private userService: usersService,
    private globalService: GlobalService,
    private storageService: StorageService,
    private changeRef: ChangeDetectorRef,
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.statusvalue = false;
    this.userId = this.globalService.getUserId();
    this.globalService.loadingSubject.next(true);
    if (this.userId != 'anonymous') {
      this.email = this.storageService.msalInfo.payload.account.username;
    } else {
      this.email = '';
    }

    this.myForm = this.formBuilder.group({
      subject: ['', [Validators.required]],
      email: [
        this.email,
        [
          Validators.required,
          Validators.pattern('[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,}'),
        ],
      ],
      message: ['', [Validators.required]],
      document: [null],
      recaptchaReactive: [null, [Validators.required]],
    });
    this.globalService.loadingSubject.next(false);
  }

  navigate() {
    this.router.navigate([AppConstants.routeUrls.home]);
  }

  selectChangeHandler(event: DropdownChangeEvent) {
    this.selectedDay = event.value;
    this.subjectOption.map(obj => {
      if (obj.value === this.selectedDay) {
        this.selectedSubject = obj.label;
      }
    }
    )
  }

  get f(): { [key: string]: AbstractControl } {
    return this.myForm.controls;
  }

  OnchangeFile(event: any) {
    this.uploadFiles.push(event.target.files[0]);
    this.myForm.patchValue({
      document: this.file,
    });
    this.myForm.get('document')?.updateValueAndValidity();
  }

  uploadImage(event: any) {
    this.sizeFlag = false;
    if (this.uploadFiles.length > 0)
      this.uploadFiles.splice(0, 1);
    let file: any = event.currentTarget.files;
    let fileName = file[0].name;
    let fileExtension = fileName
      .substring(fileName.lastIndexOf('.') + 1)
      .toLowerCase();
    this.fileType = fileName;
    let size = file[0].size;
    let sizeInMB = (Math.round(+size / 1024) / 1000).toFixed(2);
    if (parseInt(sizeInMB) > 10) {
      this.sizeFlag = true;
      return;
    }
    const allowedExtensions = ['png', 'docx', 'xlsx', 'pdf', 'jpeg', 'jpg'];
    if (!allowedExtensions.includes(fileExtension)) {
      this.typeFlag = true;
      return;
    }
    this.typeFlag = false;
    this.uploadFiles.push(file[0]);
  }

  remove(i: any) {
    this.uploadFiles.splice(i, 1);
    this.fileUploader.nativeElement.value = null;
  }

  showModal(val) {
    this.textValue = val;
    this.display = 'block';
    this.changeRef.detectChanges();
  }

  onSubmit(form: FormGroup) {
    let regex = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    let email = regex.test(this.myForm.value.email);
    if (!email) {
      this.myForm.controls['email'].setErrors({ invalidEmail: true });
    }
    if (this.selectedDay === '') this.selectedDay = null;
    this.data = {
      basesiteId: 'sgre',
      subject: this.selectedDay,
      email: this.myForm.value.email,
      message: this.myForm.value.message,
      document: this.uploadFiles,
    };
    const content_ = new FormData();
    content_.append('basesiteId', 'sgre');
    content_.append('subject', this.selectedDay);
    content_.append('message', this.myForm.value.message);
    content_.append('email', this.myForm.value.email);
    this.uploadFiles.forEach((file) => {
      content_.append('files', file, file.name);
    });
    if (this.userId != 'anonymous') {
      this.submitted = true;
      if (
        this.myForm.value.email != '' &&
        this.myForm.value.message != '' &&
        email === true &&
        this.myForm.value.subject != '' &&
        this.typeFlag === false
      ) {
        this.validation = false;
        this.service.contactusdetails(content_).subscribe({
          next: (data) => {
            this.errorFlag = false;
            if (data.status === 201) {
              this.isSuccess = 1;
              this.showModal('Message sent - An SGRE Employee will reach out');
            } else {
              this.showModal('Something went wrong.');
            }
          },
          error: (error) => {
            this.globalService.clearMessagesOnDestroy('warning');
            if (error?.error?.errors?.at(0)?.reason === 'invalid') {
              this.errorFlag = true;
              this.cdRef.markForCheck();
            }
          },
        });
      } else {
        this.validation = true;
        return;
      }
    } else {
      this.submitted = true;
      this.statusvalue = false;
      if (
        this.myForm.value.email != '' &&
        this.myForm.value.message != '' &&
        email === true &&
        this.myForm.value.subject != '' &&
        this.typeFlag === false
      ) {
        this.validation = true;
      } else {
        this.captchIndex = 0;
        return;
      }
      this.captchIndex++;
      this.captchaService.captchStatus.subscribe((status) => {
        if (status === false) {
          this.statusvalueinvalid = true;
          this.statusvaluevalid = false;
        } else if (status === true) {
          this.captchIndex = 0;
          this.statusvalue = true;
          this.statusvaluevalid = true;
          this.statusvalueinvalid = false;
        }
      });
    }
    if (this.statusvalue === true) {
      this.globalService.loadingSubject.next(true);
      this.service.contactusdetails(content_).subscribe({
        next: (data) => {
          if (data.status === 201) {
            this.errorFlag = false;
            this.globalService.loadingSubject.next(false);
            this.isSuccess = 1;
            this.showModal('Message sent - An SGRE Employee will reach out');
          } else {
            this.globalService.loadingSubject.next(false);
            this.showModal('Something went wrong.');
          }
        },
        error: (error) => {
          this.globalService.clearMessagesOnDestroy('warning');
          if (error?.error?.errors?.at(0)?.reason === 'invalid') {
            this.errorFlag = true;
            this.cdRef.markForCheck();
          }
        },
      });
    } else {
      if (this.captchIndex > 1) {
        this.statusvalueinvalid = true;
      }
    }
    if (this.myForm.invalid) {
      return;
    }
  }
  onCloseHandled() {
    this.router.navigate([AppConstants.routeUrls.home]);
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  onDrop(event: DragEvent) {
    this.sizeFlag = false;
    if (this.uploadFiles.length > 0)
      this.uploadFiles.splice(0, 1);
    event.preventDefault();
    let file: any = event.dataTransfer.files;
    let fileName = file[0].name;
    let fileExtension = fileName
      .substring(fileName.lastIndexOf('.') + 1)
      .toLowerCase();
    this.fileType = fileName;
    let size = file[0].size;
    let sizeInMB = (Math.round(+size / 1024) / 1000).toFixed(2);
    if (parseInt(sizeInMB) > 10) {
      this.sizeFlag = true;
      return;
    }
    const allowedExtensions = ['png', 'docx', 'xlsx', 'pdf', 'jpeg', 'jpg'];
    if (!allowedExtensions.includes(fileExtension)) {
      this.typeFlag = true;
      return;
    }
    this.typeFlag = false;
    this.uploadFiles.push(file[0]);
  }

  ngOnDestroy(): void {
    this.captchaService.unsubscribe();
  }

}
